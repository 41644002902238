import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { WindowLocation } from '@reach/router';
import { GITHUB_APP_ID, GITHUB_LOGIN_STATE_KEY, BROADCAST_GITHUB_LOGIN } from '../constants';
import { BroadcastChannel } from 'broadcast-channel';

const SIGN_COMPLETE_PAGE = '/github_signin_complete';

export type onLogin = (user: string, token: string) => void;

export interface GitHubLoginButtonProps {
    on_login: onLogin,
    location: WindowLocation,
}

type MessageEvent = {
    user: string;
    token: string;
}

const openSignInWindow = (location: WindowLocation, on_login: onLogin) => {

    let redirect_uri = (location.hostname === 'localhost') ? 'https://xsoar-contribution-dev.web.app/localhost_redirect' : location.origin;
    redirect_uri = encodeURIComponent(`${redirect_uri}${SIGN_COMPLETE_PAGE}`)
    const name = "Sign in to GitHub"
    const scope = encodeURIComponent('user:email')
    const state = Math.random()
    sessionStorage.setItem(GITHUB_LOGIN_STATE_KEY, `${state}`)  // store state so we can compare it in github_signing_complete
    const url = `http://github.com/login/oauth/authorize?client_id=${GITHUB_APP_ID}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}`

    const bc = new BroadcastChannel(BROADCAST_GITHUB_LOGIN);
    bc.onmessage = (event: MessageEvent) => {
        console.log('Received broadcast event for user: ', event.user)        
        on_login(event.user, event.token)
    }
    // window features
    const strWindowFeatures =
        'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

    const windowObjectReference = window.open(url, name, strWindowFeatures);
    if (!windowObjectReference) {
        console.log('FAILED opening window for url:', url)
    }
    else {        
        windowObjectReference.focus();
    }    
};

const GitHubLoginButton: React.FC<ButtonProps & GitHubLoginButtonProps> = ({ location, on_login, ...props }) => {
    return (<Button onClick={(e) => {openSignInWindow(location, on_login)}} {...props} />)
}

export default GitHubLoginButton;
