import { Typography } from '@mui/material';
import { PageProps } from 'gatsby';
import React, { useState } from 'react';
import GitHubLoginButton, { onLogin } from '../components/GithubLoginButton';
import Page from '../components/Page';

const SignInPage: React.FC<PageProps> = ({ location }) => {
  const [state_user, set_state_user] = useState<string>();

  const on_login: onLogin = (user, token) => {
    set_state_user(user)
  }
  return (
    <Page title="Sign In">
      <Typography variant="body1" gutterBottom>
        Sign in with your Github user:
      </Typography>
      <GitHubLoginButton location={location} variant="contained" color='primary' on_login={on_login}>Login with Github</GitHubLoginButton>
      {state_user && <Typography variant="body2">Welcome: {state_user}</Typography>}
    </Page>
  );
}

export default SignInPage;
